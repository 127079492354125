import { useState } from "react"
import { getContractsBySearch } from "../../../../middleware/middleware-layer"
import { useMsal } from "@azure/msal-react"
import { debounceValue } from "../../../../utils/debounce"
import { useResourceStrings } from "../use-resource-strings"
import { navigate } from "gatsby-link"

export const useContractSearchFilteringMenu = () => {
  const {
    contractNoLabel,
    purchaseOrderLabel,
  } = useResourceStrings()
  const { accounts, instance } = useMsal()

  // Errors state management
  const defaultErrors = {
    contractNo: "",
    orderNo: "",
    fullSiteAddress: "",
  }
  const [errors, setErrors] = useState(defaultErrors)
  const resetErrors = () => setErrors(defaultErrors)

  // State management for filter options selected values
  const defaultInputValues = {
    contractNo: null,
    purchaseOrder: null,
  }
  const [contractFilterOptionsInputValues, setcontractFilterOptionsInputValues] =
    useState<Record<string, any>>(defaultInputValues)
  const resetFilterOptionsInputValues = () =>
    setcontractFilterOptionsInputValues(defaultInputValues)

  const loadFuctionMap: Record<string, any> = {
    contractNo: getContractsBySearch,
    orderNo: getContractsBySearch,
    fullSiteAddress: getContractsBySearch,
  }

  const loadOptions = (key: string) => async (inputValue: string) => {
    if (key === "contractNo") {
      const isInt = /^\+?\d+$/.test(inputValue)
      if (!isInt) {
        setErrors({
          ...defaultErrors,
          contractNo: "Number only",
        })
        return []
      }
    }
    if (inputValue.length >= 3) {
      setErrors({
        ...defaultErrors,
        [key]: "",
      })
      const debouncedInputValue = await debounceValue(inputValue, 1500)
      const loadFunc = loadFuctionMap[key]
      let options = []
      try {
        const results = await loadFunc(
          accounts,
          instance,
          debouncedInputValue,
          key
        )
        switch (key) {
          case "contractNo": {
            const contracts =
              results?.data?.internalGetContractsBySearch?.contracts || []
            options = contracts.map(
              ({
                contractId,
                contractNo,
                contractStatus,
                customerName,
                customerId,
              }: Record<string, any>) => ({
                contractStatus: contractStatus,
                customerId: customerId,
                label: `${contractNo || " "}: ${customerName}`,
                value: contractId,
              })
            )
            break
          }
          case "orderNo": {
            const contracts =
              results?.data?.internalGetContractsBySearch?.contracts || []
            options = contracts.map(
              ({ 
                orderNo, 
                contractId,
                contractNo,
                contractStatus,
                customerName, 
                customerId,
               }: Record<string, any>) => ({
                contractStatus: contractStatus,
                customerId: customerId,
                label: `${contractNo} (${customerName}): ${orderNo}`,
                value: contractId,
              })
            )
            break
          }
          case "fullSiteAddress": {
            const customers =
              results?.data?.internalGetContractsBySearch?.contracts || []
            options = customers.map(
              ({
                contractId,
                contractNo,
                contractStatus,
                customerName,
                customerId,
                fullSiteAddress,
              }: Record<string, any>) => ({
                contractStatus: contractStatus,
                customerId: customerId,
                label: `${contractNo} (${customerName}): ${fullSiteAddress}`,
                value: contractId,
              })
            )
            break
          }
        }
        return options
      } catch (err) {
        console.log(err)
        return []
      }
    } else {
      setErrors({
        ...defaultErrors,
        [key]: "Min 3 characters",
      })
      return []
    }
  }

  const onOptionSelected = (key: string) => (value: Record<string, any>) => {
    const savedPath = value.contractStatus === 'S' || value.contractStatus === 'D' ? '/saved' : ''
    navigate(
        `/cx-dashboard/customer/${value.customerId}/contracts${savedPath}/${value.value}`
    )
    setcontractFilterOptionsInputValues({
      ...defaultInputValues,
      [key]: value,
    })
  }

  const contractFilterOptionsFields = [
    {
      id: "contractNo",
      type: "asyncSelect",
      label: contractNoLabel,
      placeholder: contractNoLabel,
      loadOptions: loadOptions("contractNo"),
      handleOnChange: onOptionSelected("contractNo"),
      error: errors.contractNo,
    },
    {
      id: "orderNo",
      type: "asyncSelect",
      label: purchaseOrderLabel,
      placeholder: purchaseOrderLabel,
      loadOptions: loadOptions("orderNo"),
      handleOnChange: onOptionSelected("orderNo"),
      error: errors.orderNo,
    },
    {
      id: "fullSiteAddress",
      type: "asyncSelect",
      label: "Site Address",
      placeholder: "Site Address",
      loadOptions: loadOptions("fullSiteAddress"),
      handleOnChange: onOptionSelected("fullSiteAddress"),
      error: errors.fullSiteAddress,
    },
  ]

  const values = Object.values(contractFilterOptionsInputValues).filter(Boolean)
  const selectedCustomerId = values[0]?.value

  return {
    contractFilterOptionsFields,
    contractFilterOptionsInputValues,
    selectedCustomerId,
    resetFilterOptionsInputValues,
    resetErrors,
  }
}
